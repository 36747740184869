<template>
  <nav class="header__menu header__menu--special menu">
    <div class="header__menu-inner container">
      <div class="header__menu-logo">
        <router-link :to="{ name:'HomePage' }">
          <img
            src="@/assets/images/logo-default.svg"
            class="logo-default"
            alt="GIRA"
            width=""
            height=""
          >
        </router-link>
     
        <router-link :to="{ name:'HomePage' }">
          <img
            src="@/assets/images/logo-black.svg"
            class="logo-black"
            alt="GIRA"
            width=""
            height=""
          >
        </router-link>
      </div>
      <ul class="header__list">
        <li class="header__list-item">
          <router-link
            :to="{ name:'HomePage' }"
            class="item-link"
          >
            Главная
          </router-link>
        </li>
        <li class="header__list-item">
          <router-link
            :to="{ name:'AboutPage' }"
            class="item-link"
          >
            О нас
          </router-link>
        </li>
        <li class="header__list-item">
          <router-link
            :to="{ name:'ServicesPage' }"
            class="item-link"
          >
            Услуги
          </router-link>
        </li>
        <li class="header__list-item">
          <router-link
            :to="{ name:'ContactsPage' }"
            class="item-link"
          >
            Контакты
          </router-link>
        </li>
      </ul>
    </div>
    <mobile-menu />
  </nav>
</template>
<script>
export default {
    name: 'HeaderBlack',
    components: {
        MobileMenu: () => import('@/components/MobileMenu')
    },
    beforeCreate() {
        window.addEventListener('scroll', function () {
            let scrollPos = window.scrollY
            let header = document.querySelector('.header__menu')
            let logoDefault = document.querySelector('.logo-default')
            let logoSpecial = document.querySelector('.logo-black')
            if (header && scrollPos > 10) {
                header.classList.add('header__menu--active')
                logoSpecial.style.display = 'block'
                logoDefault.style.display = 'none'
            } else {
                header.classList.remove('header__menu--active')
                logoSpecial.style.display = 'none'
                logoDefault.style.display = 'block'
            }
        })
    }
}
</script>
